@import url('https://fonts.googleapis.com/css?family=Roboto');

body {
    font-family: 'Roboto', sans-serif;
    background-image: url(../public/bg.jpg);
}
a {
    color: inherit !important;
    text-decoration: none !important;
}

